import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  // @ts-ignore SENTRY_FRONTEND_DSN as it's a placeholder for Webpack.
  dsn: SENTRY_FRONTEND_DSN,
  // @ts-ignore RAILS_ENV as it's a placeholder for Webpack.
  environment: RAILS_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});
