export function isValidAustrianNumber(verificationNumber: string) {
  const weights = [7, 3, 1];
  const [id, idChecksum] = verificationNumber.split("-");

  const checksum = id.split("").reduce((sum, char, index) => {
    const digit = parseInt(char, 10);
    return sum + digit * weights[index % weights.length];
  }, 0);

  return parseInt(idChecksum, 10) === checksum % 100;
}

export function isValidGermanNumber(verificationNumber: string) {
  if (verificationNumber.length !== 15) {
    return false;
  }

  const id = verificationNumber.substring(0, 14);
  const idChecksum = verificationNumber.substring(14);

  const checksum = calculateGermanChecksum(id);

  return parseInt(idChecksum, 10) === checksum % 10;
}

function calculateGermanChecksum(id: string) {
  return id
    .split("")
    .reverse()
    .reduce((sum, char, index) => {
      let digit = parseInt(char, 10);

      if ((index + 1) % 2 === 0) {
        digit = digit * 2;

        if (digit > 9) {
          digit -= 9;
        }
      }

      return sum + digit;
    }, 0);
}
