$(document).ready(() => {
  const $password = $<HTMLInputElement>("#password");
  const $passwordToggle = $<HTMLInputElement>("#password_toggle");

  function setPasswordVisibility(toggleElement: HTMLInputElement | undefined) {
    if (toggleElement?.checked) {
      $password.attr("type", "text");
    } else {
      $password.attr("type", "password");
    }
  }

  $passwordToggle
    .change((event) =>
      setPasswordVisibility(event.currentTarget as HTMLInputElement | undefined)
    )
    .trigger("change"); // Trigger event once on startup to handle initial state
});
